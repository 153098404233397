
.video-player-container {
  min-height: 90vh;
  background-color: #181818; 
  padding: 20px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.video-section {
  position: relative;
  width: 100%;
  max-width: 1280px; /* This limits the width similar to YouTube */
  margin: 0 auto;
  background-color: #000; 
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
}

.video-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: #000;
  overflow: hidden;
}
.aspect-ratio-box2 {
  position: relative;
  width: 100%;
  /* padding-bottom: 56.25%; */
  background-color: #000;
  overflow: hidden;
}

/* Tiêu đề video */
.video-title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  border-bottom: 1px solid #303030;
}

/* Chi tiết video */
.video-details {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

/* Thông tin kênh */
.channel-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.channel-link {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}

.channel-link:hover {
  text-decoration: underline;
}

.verified-icon {
  font-size: 16px !important;
  color: #4caf50; 
  margin-left: 4px;
}

.channel-subscribe {
  margin-left: 8px;
  color: #aaa;
  cursor: pointer;
  font-size: 14px;
}

.channel-subscribe:hover {
  color: #fff;
}

/* Thống kê video */
.video-stats {
  display: flex;
  gap: 24px;
}

.video-stats > * {
  color: #aaa;
  font-size: 14px;
}

.video-stats > *:hover {
  color: #fff;
  cursor: pointer;
}

/* Phần video liên quan (Placeholder) */
.related-videos-section {
  width: 100%;
  max-width: 800px;
  margin-top: 24px;
  /* Bạn có thể thêm các video liên quan ở đây */
}



/* Responsive */
@media (max-width: 768px) {
  .video-player-container {
    padding: 10px;
  }

  .video-section {
    max-width: 100%;
  }

  .video-title {
    font-size: 16px;
    padding: 12px;
  }

  .video-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .video-stats {
    gap: 12px;
  }
}
@media (max-width: 768px) {
  .video-player-container {
    flex-direction: column; /* Change to column direction on smaller screens */
  }
}